import axios from './request'

// 登录
export function login(params) {
  return axios({
    method: 'get',
    url: 'system/login',
    params,
  })
}
// 退出登录
export function logOut() {
  return axios({
    method: 'get',
    url: 'system/logout',
  })
}

// 区域名称
// 查询除市及以外的区域名称
export function queryWithPageAll(params) {
  return axios({
    method: 'get',
    url: 'rad/area/query',
    params,
  })
}
// 分页查询所有区域名称
export function queryWithPage(params) {
  return axios({
    method: 'get',
    url: 'rad/area/queryAll',
    params,
  })
}
// 新增区域名称
export function areaSave(data) {
  return axios({
    method: 'post',
    url: 'rad/area/save',
    data,
  })
}
// 编辑区域名称
export function areaUpdate(data) {
  return axios({
    method: 'post',
    url: 'rad/area/update',
    data,
  })
}
// 删除区域名称
export function areaDeleteById(data) {
  return axios({
    method: 'post',
    url: 'rad/area/delete',
    data,
  })
}

// 机构管理
// 分页查询所有机构
export function organizationQueryWithPage(params) {
  return axios({
    method: 'get',
    url: 'rad/organization/query',
    params,
  })
}
// 新增机构
export function organizationSave(data) {
  return axios({
    method: 'post',
    url: 'rad/organization/save',
    data,
  })
}
// 修改机构
export function organizationUpdate(data) {
  return axios({
    method: 'post',
    url: 'rad/organization/update',
    data,
  })
}
// 删除机构
export function organizationDelete(data) {
  return axios({
    method: 'post',
    url: 'rad/organization/delete',
    data,
  })
}

// 设备管理
// 分页查询所有设备
export function deviceQueryWithPage(params) {
  return axios({
    method: 'get',
    url: 'rad/device/query',
    params,
  })
}
// 新增设备
export function deviceSave(data) {
  return axios({
    method: 'post',
    url: 'rad/device/save',
    data,
  })
}
// 修改设备
export function deviceUpdate(data) {
  return axios({
    method: 'post',
    url: 'rad/device/update',
    data,
  })
}
// 删除设备
export function deviceDelete(data) {
  return axios({
    method: 'post',
    url: 'rad/device/delete',
    data,
  })
}

// 设备使用者管理
// 分页查询所有设备使用者
export function deviceuserQueryWithPage(params) {
  return axios({
    method: 'get',
    url: 'rad/deviceUser/query',
    params,
  })
}
// 新增设备使用者
export function deviceuserSave(data) {
  return axios({
    method: 'post',
    url: 'rad/deviceUser/save',
    data,
  })
}
// 修改设备使用者
export function deviceuserUpdate(data) {
  return axios({
    method: 'post',
    url: 'rad/deviceUser/update',
    data,
  })
}
// 删除设备使用者
export function deviceuserDelete(data) {
  return axios({
    method: 'post',
    url: 'rad/deviceUser/delete',
    data,
  })
}

// 监控管理
// 查询监控
export function cameraQuery(params) {
  return axios({
    method: 'get',
    url: 'system/camera/query',
    params,
  })
}
// 新增监控
export function cameraSave(data) {
  return axios({
    method: 'post',
    url: 'system/camera/save',
    data,
  })
}
// 修改监控
export function cameraUpdate(data) {
  return axios({
    method: 'post',
    url: 'system/camera/update',
    data,
  })
}
// 删除监控
export function cameraDelete(data) {
  return axios({
    method: 'post',
    url: 'system/camera/delete',
    data,
  })
}
// 监控回放
export function cameraGetDetail(data) {
  return axios({
    method: 'post',
    url: 'system/camera/getDetail',
    data,
  })
}

// 阈值管理
// 查询所有参数设备类型
export function radCommonQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/common/query',
    params,
  })
}
// 查询阈值
export function queryThreshold(params) {
  return axios({
    method: 'get',
    url: 'rad/common/queryThreshold',
    params,
  })
}
// 修改阈值
export function updateThreshold(data) {
  return axios({
    method: 'post',
    url: 'rad/common/update',
    data,
  })
}

// 异常告警
// 分页查询个人异常告警记录
export function recordexceptionQueryWithPagePersonal(params) {
  return axios({
    method: 'get',
    url: 'rad/exception/queryWithPage',
    params,
  })
}
// 处理异常
export function recordexceptionUpdate(data) {
  return axios({
    method: 'post',
    url: 'rad/exception/update',
    data,
  })
}

// 历史数据
// 1001固定式
export function recordGdsQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordGds/query',
    params,
  })
}
// 核素药物
export function recordNuclideQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordNuclide/query',
    params,
  })
}
// dsa个人剂量腕式
export function recordDsaQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDsa/query',
    params,
  })
}
// 个人剂量仪
export function recordDoseQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDose/query',
    params,
  })
}
// 便携式表面污染仪
export function recordSurfaceQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordSurface/query',
    params,
  })
}
// 手足表面污染仪
export function recordHandFootQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordHandFoot/query',
    params,
  })
}
// X、Y辐射率巡测仪
export function recordCircuitQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordCircuit/query',
    params,
  })
}
// 二维中子实时探测仪
export function recordNeutronQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordNeutron/query',
    params,
  })
}
// 通道
export function recordRecordChannelQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordChannel/query',
    params,
  })
}
// 通过详情id图片
export function recordChannelGetById(params) {
  return axios({
    method: 'get',
    url: 'rad/recordChannel/getById',
    params,
  })
}

// 人员管理
// 管理员列表
// 查询管理员
export function adminQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/admin/query',
    params,
  })
}
// 新增管理员
export function adminSave(data) {
  return axios({
    method: 'post',
    url: 'rad/admin/save',
    data,
  })
}
// 修改管理员
export function adminUpdate(data) {
  return axios({
    method: 'post',
    url: 'rad/admin/update',
    data,
  })
}
// 修改管理员状态
export function adminChangeUseFlag(params) {
  return axios({
    method: 'get',
    url: 'rad/admin/changeUseFlag',
    params,
  })
}
// 更改管理员密码
export function adminChangePassword(params) {
  return axios({
    method: 'get',
    url: 'rad/admin/changePassword',
    params,
  })
}

// 角色列表
// 查询角色
export function roleQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/role/query',
    params,
  })
}
// 新增角色
export function roleSave(data) {
  return axios({
    method: 'post',
    url: 'rad/role/save',
    data,
  })
}
// 修改角色
export function roleUpdate(data) {
  return axios({
    method: 'post',
    url: 'rad/role/update',
    data,
  })
}
// 修改角色状态
export function roleChangeUseFlag(params) {
  return axios({
    method: 'get',
    url: 'rad/role/changeUseFlag',
    params,
  })
}
// 更改角色权限
export function roleEditRolePrivilege(data) {
  return axios({
    method: 'post',
    url: 'rad/role/editRolePrivilege',
    data,
  })
}
// 获取所有菜单和角色权限
export function roleGetAllMenuAndRoleHasPrivilege(params) {
  return axios({
    method: 'get',
    url: 'rad/role/getAllMenuAndRoleHasPrivilege',
    params,
  })
}

// 日志管理
// 查询所有日志
export function roleLogsQueryAll(params) {
  return axios({
    method: 'get',
    url: 'rad/logs/queryAll',
    params,
  })
}

// 首页
// 左上角
export function roleAnalysisDateCount(params) {
  return axios({
    method: 'get',
    url: 'rad/analysis/dateCount',
    params,
  })
}
// 全部未处理异常
export function radAnalysisGetAllTop3NotAdjustException(params) {
  return axios({
    method: 'get',
    url: 'rad/analysis/getAllTop3NotAdjustException',
    params,
  })
}
// 今日已处理
export function radAnalysisGetTodayTop3AdjustException(params) {
  return axios({
    method: 'get',
    url: 'rad/analysis/getTodayTop3AdjustException',
    params,
  })
}
// 今日新增异常
export function radAnalysisGetTodayTop3NewException(params) {
  return axios({
    method: 'get',
    url: 'rad/analysis/getTodayTop3NewException',
    params,
  })
}
// 异常信息（一周）
export function radAnalysisGetException(params) {
  return axios({
    method: 'get',
    url: 'rad/analysis/getException',
    params,
  })
}
// 图表数据（一周）
export function radAnalysisGet(params) {
  return axios({
    method: 'get',
    url: 'rad/analysis/get',
    params,
  })
}

// 动态获取路由数据
export function systemGetAllMenuAndUserHasPrivilege(params) {
  return axios({
    method: 'get',
    url: 'system/getAllMenuAndUserHasPrivilege',
    params,
  })
}

// 导出固定式历史记录
export function radRecordGdsDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordGds/downloadExcel',
    params,
    responseType: 'blob',
  })
}

// 导出核素历史记录
export function radRecordNuclideDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordNuclide/downloadExcel',
    params,
    responseType: 'blob',
  })
}

// 导出dsa历史记录
export function radRecordDsaDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDsa/downloadExcel',
    params,
    responseType: 'blob',
  })
}

// 导出个人剂量仪历史记录
export function radRecordDoseDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDose/downloadExcel',
    params,
    responseType: 'blob',
  })
}

// 导出便携式表面污染仪历史记录
export function radRecordSurfaceDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordSurface/downloadExcel',
    params,
    responseType: 'blob',
  })
}

// 导出手足表面污染仪历史记录
export function radRecordHandFootDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordHandFoot/downloadExcel',
    params,
    responseType: 'blob',
  })
}

// 导出X、Y辐射率巡测仪历史记录
export function radRecordCircuitDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordCircuit/downloadExcel',
    params,
    responseType: 'blob',
  })
}

// 导出二维中子实时探测仪历史记录
export function radRecordNeutronDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordNeutron/downloadExcel',
    params,
    responseType: 'blob',
  })
}

// 导出通道历史记录
export function radRecordChannelDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordChannel/downloadExcel',
    params,
    responseType: 'blob',
  })
}
// 查询行人通道历史记录
export function radRecordPedestrianChannelQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordPedestrianChannel/query',
    params,
  })
}
// 导出通道历史记录
export function radRecordPedestrianChannelDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordPedestrianChannel/downloadExcel',
    params,
    responseType: 'blob',
  })
}
// 查询直饮水历史记录
export function radRecordDrinkWaterQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDrinkWater/query',
    params,
  })
}
// 导出直饮水历史记录
export function radRecordDrinkWaterDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDrinkWater/downloadExcel',
    params,
    responseType: 'blob',
  })
}
// 查询衰变池历史记录
export function radRecordDecayQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDecay/query',
    params,
  })
}
// 导出衰变池历史记录
export function radDecayRecordDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDecay/downloadExcel',
    params,
    responseType: 'blob',
  })
}
// 衰变槽排水记录
export function radRecordDischargeQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDischarge/query',
    params,
  })
}
// 衰变槽排水记录
export function radRecordDischargeDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/recordDischarge/downloadExcel',
    params,
    responseType: 'blob',
  })
}
// 蓝牙设备解绑
export function radMqttUnbindDevice(params) {
  return axios({
    method: 'get',
    url: 'rad/mqtt/unbindDevice',
    params,
  })
}

// 患者管理

// 添加患者
export function radPatientSave(data) {
  return axios({
    method: 'post',
    url: 'rad/patient/save',
    data,
  })
}
// 添加患者
export function radPatientUpdate(data) {
  return axios({
    method: 'post',
    url: 'rad/patient/update',
    data,
  })
}
// 按条件查询所有患者
export function radPatientQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/patient/query',
    params,
  })
}
// 更改患者状态
export function radPatientChangeUseFlag(params) {
  return axios({
    method: 'get',
    url: 'rad/patient/changeUseFlag',
    params,
  })
}
// 导出患者信息
export function radPatientDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/patient/downloadExcel',
    params,
    responseType: 'blob',
  })
}
// 导入模板
export function radPatientDownloadTemplate(params) {
  return axios({
    method: 'get',
    url: 'rad/patient/downloadTemplate',
    params,
    responseType: 'blob',
  })
}
// 批量导入患者信息
export function radPatientImport(data) {
  return axios({
    method: 'post',
    url: 'rad/patient/import',
    data,
    responseType: 'blob',
  })
}
// 根据身份证查询某一患者
export function radPatientGetByIdCard(data) {
  return axios({
    method: 'post',
    url: 'rad/patient/getByIdCard',
    data,
  })
}
// 开始测量
export function radPatientDoserateStartMonitor(params) {
  return axios({
    method: 'get',
    url: 'rad/patientDoserate/startMonitor',
    params,
  })
}
// 结束测量
export function radPatientDoserateEndMonitor(params) {
  return axios({
    method: 'get',
    url: 'rad/patientDoserate/endMonitor',
    params,
  })
}
// 按条件查询患者剂量检测历史数据
export function radPatientDoserateQuery(params) {
  return axios({
    method: 'get',
    url: 'rad/patientDoserate/query',
    params,
  })
}
// 采集列表
export function radPatientDoserateGetPatientDoserateList(params) {
  return axios({
    method: 'get',
    url: 'rad/patientDoserate/getPatientDoserateList',
    params,
  })
}
// 导出患者剂量检测历史数据
export function radPatientDoserateDownloadExcel(params) {
  return axios({
    method: 'get',
    url: 'rad/patientDoserate/downloadExcel',
    params,
    responseType: 'blob',
  })
}
// 重置
export function radPatientDoserateUpdateWorkState(params) {
  return axios({
    method: 'get',
    url: 'rad/patientDoserate/updateWorkState',
    params,
  })
}
// 患者再次入院
export function radPatientReadmission(params) {
  return axios({
    method: 'get',
    url: 'rad/patient/readmission',
    params,
  })
}
// 下载读取身份证插件
export function radPatientDownloadIdCard(params) {
  return axios({
    method: 'get',
    url: 'rad/patient/downloadIdCard',
    params,
    responseType: 'blob',
  })
}
export function radPatientDoserateDelete(data) {
  return axios({
    method: 'post',
    url: 'rad/patientDoserate/delete',
    data,
  })
}
