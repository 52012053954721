import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import store from '../store'

if (process.env.NODE_ENV == 'development') {
  //开发环境
  axios.defaults.baseURL = 'api'
  // axios.defaults.baseURL = 'http://192.168.1.54:9300/'
} else {
  axios.defaults.baseURL = '/' // 生产环境
}

const instance = axios.create({
  timeout: 30000,
})

// 导出接口
const exportApi = [
  'rad/recordGds/downloadExcel',
  'rad/recordNuclide/downloadExcel',
  'rad/recordDsa/downloadExcel',
  'rad/recordDose/downloadExcel',
  'rad/recordSurface/downloadExcel',
  'rad/recordHandFoot/downloadExcel',
  'rad/recordCircuit/downloadExcel',
  'rad/recordNeutron/downloadExcel',
  'rad/recordChannel/downloadExcel',
  'rad/recordPedestrianChannel/downloadExcel',
  'rad/recordDrinkWater/downloadExcel',
  'rad/recordDecay/downloadExcel',
  'rad/recordDischarge/downloadExcel',
  'rad/patientDoserate/downloadExcel',
  'rad/patient/downloadExcel',
  'rad/patient/downloadTemplate',
  'rad/patient/import',
  'rad/patient/downloadIdCard',
]

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    if (store.state.token != '') {
      config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Access-Token': store.state.token,
      }
    }
    return config
  },
  (error) => {
    console.log('instance .interceptors.request', error)
    return Promise.reject(error)
  }
)
// 响应拦截
let status = true
instance.interceptors.response.use(
  (response) => {
    const result = response.data
    if (status) {
      if (exportApi.includes(response.config.url)) {
        return result
      }
      if (result.code == 200) {
        return result
      }
      if (result.code == 401) clearAll()
      timeoutRes(result.message)
    }
  },
  (error) => {
    if (status) {
      if (error.message.includes('timeout')) {
        timeoutRes('请求超时,请稍后再试')
        return Promise.reject(error)
      }
      timeoutRes('网络连接失败，请稍后再试')
      return Promise.reject(error)
    }
  }
)
// 清空缓存信息
function clearAll() {
  store.dispatch('SETNAV', [])
  store.commit('user', '')
  store.commit('token', '')
  store.commit('deviceId', [null, null])
  sessionStorage.clear()
  router.push('/SignIn')
}
// 阻止多次弹框
function timeoutRes(message) {
  status = false
  Vue.prototype.$message.error(message)
  let timeout = setTimeout(() => {
    status = true
    clearTimeout(timeout)
  }, 1500)
}

export default instance
