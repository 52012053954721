import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// 导入进度条动画
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { systemGetAllMenuAndUserHasPrivilege } from '@/util/http'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/SignIn',
    component: () => import('@/views/Login/SignIn.vue'),
  },
  {
    // 首页
    path: '/Home',
    component: () => import('../views/Home.vue'),
  },
]

const router = new VueRouter({
  routes,
  mode: 'hash',
})
const titIcon = ['el-icon-s-data', 'el-icon-s-operation', 'el-icon-s-management', 'el-icon-cpu', 'el-icon-s-finance', 'el-icon-s-claim', 'el-icon-collection-tag', 'el-icon-film', 'el-icon-s-marketing', 'el-icon-receiving', 'el-icon-s-opportunity', 'el-icon-truck', 'el-icon-wind-power', 'el-icon-s-tools'] //一级菜单图标
const icon = [
  'el-icon-s-flag',
  'el-icon-s-promotion',
  'el-icon-s-help',
  'el-icon-help',
  'el-icon-s-platform',
  'el-icon-s-shop',
  'el-icon-s-order',
  'el-icon-s-tools',
  'el-icon-warning',
  'el-icon-s-management',
  'el-icon-s-tools',
  'el-icon-warning',
  'el-icon-cpu',
  'el-icon-s-tools',
  'el-icon-warning',
  'el-icon-s-finance',
  'el-icon-s-tools',
  'el-icon-warning',
  'el-icon-s-claim',
  'el-icon-s-tools',
  'el-icon-warning',
  'el-icon-collection-tag',
  'el-icon-s-tools',
  'el-icon-warning',
  'el-icon-film',
  'el-icon-s-tools',
  'el-icon-warning',
  'el-icon-s-marketing',
  'el-icon-s-tools',
  'el-icon-warning',
  'el-icon-receiving',
  'el-icon-user',
  'el-icon-s-custom',
] //二级菜单图标
router.beforeEach((to, from, next) => {
  // 提示的进度条
  NProgress.inc(0.2)
  NProgress.configure({ easing: 'ease', speed: 700, showSpinner: false })
  if (to.path == '/SignIn') {
    //去登录页
    if (store.state.token) {
      //是否已登录
      Vue.prototype.$message.success('您已登录')
      NProgress.done()
      next(false)
      return false
    } else {
      next()
    }
  } else {
    //去非登录页
    if (store.state.token) {
      //是否已登录
      autoGetMenuAuthority(store.state.user.id)
      next()
    } else {
      NProgress.done() //结束进度条
      next({ path: '/SignIn' })
    }
  }
})

router.afterEach(() => {
  NProgress.done() //结束进度条
})

// 获取菜单权限
function autoGetMenuAuthority(adminId) {
  if (store && store.state.nav.length == 0) {
    systemGetAllMenuAndUserHasPrivilege({ adminId }).then((data) => {
      if (data) {
        let checkedMenu = data.result.userPrivileges
        var menuObjList = []
        data.result.menu.slice(1).map((val, i) => {
          var oneMenu = {}
          oneMenu.menuArr = []
          oneMenu.titId = i.toString()
          oneMenu.titIcon = titIcon[i]
          oneMenu.moduleCode = val.moduleCode
          oneMenu.moduleId = val.moduleId
          oneMenu.titName = val.moduleName
          oneMenu.parentCode = val.parentCode
          val.children.map((vals, index) => {
            var twoMenu = {}
            twoMenu.children = []
            twoMenu.id = vals.moduleCode
            twoMenu.path = '/' + vals.moduleCode
            twoMenu.icon = icon[index]
            twoMenu.moduleId = vals.moduleId
            twoMenu.name = vals.moduleName
            twoMenu.parentCode = vals.parentCode
            vals.children.map((valsp) => {
              for (var i = 0; i < checkedMenu.length; i++) {
                if (checkedMenu[i] == valsp.id) {
                  twoMenu.children.push(valsp)
                }
              }
            })
            if (twoMenu.children.length != 0) {
              oneMenu.menuArr.push(twoMenu)
            }
          })
          if (oneMenu.menuArr.length != 0) {
            menuObjList.push(oneMenu)
          }
        })
        if (menuObjList[menuObjList.length - 1].moduleCode == 'Channel') {
          menuObjList.splice(-1, 1, ...menuObjList.splice(-3, 1, menuObjList[menuObjList.length - 1]))
        }
        store.dispatch('SETNAV', menuObjList)
        // console.log(menuObjList);
        var data = routesData(menuObjList)
        // router.addRoutes(data);
        for (let i = 0; i < data.length; i++) {
          router.addRoute(data[i])
        }
      }
    })
  }
}

function routesData(result) {
  result.map((val) => {
    val.menuArr.map((vals) => {
      routes.push({
        path: vals.path,
        component: () => import('@/views/' + vals.id + '.vue'),
      })
    })
  })
  return routes
}

export default router
